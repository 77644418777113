<template>
  <div>
    <div v-for="activity in activities" :key="activity.userId">
      <div v-for="quoteId in activity.data.quoteIds" :key="quoteId">
        <el-timeline-item
          v-if="quoteId"
          :icon="'el-icon-document'"
          :color="formatActivityColor"
          size="large"
          :timestamp="formatDate(activity.createdOn)"
        >
          <div
            v-html="
              `${accountTypeFormatted(activity)} | ${activityUrl(quoteId)}`
            "
          ></div>
          <p>
            {{ activity.data.reason ? `Reason; ${activity.data.reason}` : "" }}
          </p>
        </el-timeline-item>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from "date-fns";
import { capitalize, get } from "lodash";
export default {
  name: "PrintActivities",
  props: {
    activities: [],
    type: {
      type: String
    }
  },
  data() {
    return {
      anchorColor: "#409EFF"
    };
  },
  methods: {
    formatDate(date_str) {
      return format(new Date(date_str), "MMMM d, y");
    },
    accountTypeFormatted(activity) {
      let nameToDisplay = "";
      if (this.$getCurrentUser._id === activity.userId) {
        nameToDisplay = "You";
      } else {
        const fullName = get(activity.userData, "fullName", "") || "";
        const firstName = get(activity.userData, "firstName", "") || "";
        const lastName = get(activity.userData, "lastName", "") || "";
        nameToDisplay = `${firstName} ${lastName}`;
        if (fullName) {
          nameToDisplay = fullName;
        }
      }

      return `<a href="" style="color: ${this.anchorColor}">${capitalize(
        nameToDisplay
      )}</a> ${this.formateText}`;
    },
    activityUrl(activityId) {
      return `<a style="color: ${this.anchorColor} " href="/quotes/${activityId}" class="font-bold" target="_blank"> View Quote </a>`;
    }
  },
  computed: {
    formatActivityColor() {
      return this.type === "failed" ? "#EF4544" : "#67c23a";
    },
    formateText() {
      return this.type === "failed"
        ? "failed to print quote"
        : "printed quote successfully";
    }
  }
};
</script>
