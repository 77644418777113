<template>
  <div>
    <el-timeline-item
      v-if="content"
      :icon="icon"
      :type="type"
      :color="color"
      size="large"
      :timestamp="formatDate(activity.createdOn)"
    >
      <div class="flex gap-4">
        <div v-html="formattedActivityText" class=""></div>
        <span
          v-if="displayRatingActivity && sortedData && sortedData.length"
          v-on:click="expandRatingView"
        >
          <font-awesome-icon
            :icon="[
              'fal',
              !ratingViewExpanded ? 'chevron-right' : 'chevron-down'
            ]"
            class="text-blue-700 hover:text-blue-400 mr-2"
          />
        </span>
      </div>
      <!-- <FontAwesomeIcon v-on:click="expandRatingView" /> -->
      <section v-if="ratingViewExpanded" style="margin-left: 35px">
        <ratings-activity :ratings="sortedData" />
      </section>
    </el-timeline-item>
    <div v-if="failedQuoteActivities.length > 0">
      <PrintActivities :activities="failedQuoteActivities" type="failed" />
    </div>
    <div v-if="successQuoteActivities.length > 0">
      <PrintActivities :activities="successQuoteActivities" type="success" />
    </div>
  </div>
</template>

<script>
import { format } from "date-fns";
import { capitalize, get } from "lodash";
import { userMapGetters } from "@/store/modules/user";
import { quoteMapActions } from "@/store/modules/quote";
import { authMapState } from "@/store/modules/auth";
import QuoteActivitiesMixin from "@/views/shared/quotes/QuotesActivities/QuoteActivitiesMixin.vue";
import ActivitiesTimelineMixin from "@/components/ActivitiesView/ActivitiesTimelineMixin.vue";
import PrintActivities from "@/views/shared/quotes/QuotesActivities/PrintActivities.vue";
import RatingsActivity from "@/views/shared/quotes/Components/RatingsActivity.vue";
export default {
  components: { PrintActivities, RatingsActivity },
  mixins: [ActivitiesTimelineMixin, QuoteActivitiesMixin],
  props: {
    activity: {
      type: Object,
      required: true
    },
    userRole: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      failedQuoteActivities: [],
      successQuoteActivities: []
    };
  },
  methods: {
    ...quoteMapActions(["getQuote"]),
    formatDate(date_str) {
      return format(new Date(date_str), "MMMM d, y");
    },
    generateReadableGeneric({ content, icon, type, link }) {
      this.content = `{userRole} ${content}`;
      this.icon = icon;
      this.type = type;
      this.link = link ? link : this.accountTypeFormatted;
    },
    generateReadableScreenView() {
      let screenName = get(this.data, "screenName");
      this.content = `{userRole} moved to screen: ${screenName}.`;
      this.icon = "el-icon-s-platform";
      this.link = this.accountTypeFormatted;
    },
    generateReadableSendgridEvent() {
      const { messageType = "" } = this.data;
      this.content = messageType
        ? `A new ${messageType} Sendgrid event was generated for {userRole}`
        : `A Sendgrid event was generated for {userRole}`;
      this.icon = "el-icon-user";
      this.type = "warning";
      this.link = this.accountTypeFormatted;
    },
    generateReadableUserUpdate() {
      const wording =
        this.activity.userId === this.data.userId
          ? "their account"
          : `this <a href='/admin/users/${this.data.userId}/edit' class='font-bold' style='color:${this.linkColor}' target='_blank'>user's</a>`;
      this.content = `<p>{editingUser} updated ${wording} information.</p>`;
      //data.updates to html list
      this.content = this.content + `<ul>`;
      this.data.updates.forEach(update => {
        this.content =
          this.content + `<li>${update.field} updated to ${update.update}</li>`;
      });
      this.content = this.content + `</ul>`;
      this.icon = "el-icon-user";
      this.type = "warning";
      this.link = {
        ...this.accountTypeFormatted,
        editingUser: {
          name: this.activity.userData.fullName,
          href: "/admin/users/" + this.activity.userId + "/edit"
        },
        editedUser: {
          name: capitalize(get(this.getEditingUser, "fullName", "N/A")),
          href: "/admin/users/" + get(this.activity, "data.userId") + "/edit"
        }
      };
    },
    generateReadableUserAdded() {
      this.content = `<p>{editingUser} added this user: {addedUser}.</p>`;
      this.icon = "el-icon-user";
      this.type = "success";
      this.link = {
        ...this.accountTypeFormatted,
        editingUser: {
          name: this.activity.userData.fullName,
          href: "/admin/users/" + this.activity.userId + "/edit"
        },
        addedUser: {
          name: capitalize(get(this.getEditingUser, "fullName", "N/A")),
          href: "/admin/users/" + get(this.activity, "data.userId") + "/edit"
        }
      };
    },
    extraCreatedCompanyForActivites(companyId) {
      const companies = this.initData?.companies || [];
      const newlyCreatedCompany = companies.find(
        company => company._id === companyId
      );
      return newlyCreatedCompany;
    },
    formatFieldChangeText(text) {
      return text
        .split(".")
        .join(" ")
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .toLowerCase();
    }
  },
  mounted() {
    this.formatRatingActivity();
  },
  computed: {
    ...userMapGetters(["getEditingUser"]),
    ...authMapState(["initData"]),
    formattedActivityText() {
      const linksArray = Object.keys(this.link);
      let content = this.content;
      if (linksArray.length) {
        linksArray.map(linkKey => {
          const link = this.link[linkKey];
          if (link) {
            const { name, href } = link;
            let linkNode;
            if (href) {
              linkNode = `<a style="color: ${this.linkColor}" href="${href ||
                "#"}"> ${name} </a>`;
            } else if (name) {
              linkNode = `<span> ${name} </span>`;
            } else {
              linkNode = "";
            }
            const reg = new RegExp(`{${linkKey}}`, "g");
            content = content.replace(reg, linkNode);
          }
        });
      }
      return content;
    },
    data() {
      return this.activity.data;
    },
    accountTypeFormatted() {
      const fullName = get(this.activity, "userData.fullName", this.userRole);
      let link = {
        name: capitalize(fullName),
        href: `edit`
      };
      return { userRole: link };
    }
  },
  watch: {
    activity: {
      async handler() {
        this.content = "";
        this.icon = "";
        this.type = "";
        this.link = {};
        this.color = "";
        switch (this.activity.name) {
          case "LOGIN_SUCCESS":
            this.generateReadableGeneric({
              content: "logged in.",
              icon: "el-icon-user-solid",
              type: "success"
            });
            break;
          case "LOGOUT":
            this.generateReadableGeneric({
              content: "logged out.",
              icon: "el-icon-user-solid",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "MFA_LOGIN_SUCCESS":
            this.generateReadableGeneric({
              content: "logged in with MFA.",
              icon: "el-icon-user-solid",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "CONFIRM_ACCOUNT_INIT_SUCCESS":
            this.generateReadableGeneric({
              content: "confirmation token was generated.",
              icon: "el-icon-user-solid",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "CONFIRM_ACCOUNT_SUCCESS":
            this.generateReadableGeneric({
              content: "was confirmed.",
              icon: "el-icon-user-solid",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "CONFIRM_ACCOUNT_FAILURE":
            this.generateReadableGeneric({
              content: "confirmation failed.",
              icon: "el-icon-user-solid",
              type: "danger",
              link: this.accountTypeFormatted
            });
            break;
          case "CLAIM_ACCOUNT_INIT_SUCCESS":
            this.generateReadableGeneric({
              content: "claim token was generated.",
              icon: "el-icon-user-solid",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "CLAIM_ACCOUNT_SUCCESS":
            this.generateReadableGeneric({
              content: "account was claimed.",
              icon: "el-icon-user-solid",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "CLAIM_ACCOUNT_FAILURE":
            this.generateReadableGeneric({
              content: "account claim failed.",
              icon: "el-icon-user-solid",
              type: "danger",
              link: this.accountTypeFormatted
            });
            break;
          case "PASSWORD_RESET_SUCCESS":
            this.generateReadableGeneric({
              content: "successfully reset password.",
              icon: "el-icon-user",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "PASSWORD_RESET_FAILURE":
            this.generateReadablePasswordResetFailure();
            this.generateReadableGeneric({
              content: "failed to reset password.",
              icon: "el-icon-user",
              type: "danger",
              link: this.accountTypeFormatted
            });
            break;
          case "UPDATE_USER_SUCCESS":
            this.generateReadableUserUpdate();
            break;
          case "ADD_USER_SUCCESS":
            this.generateReadableUserAdded();
            break;
          case "FILE_ACCESS_SUCCESS":
            this.generateReadableGeneric({
              content: `accessed file ${this.data.fileName}`,
              icon: "el-icon-user",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "FILE_ACCESS_FAILURE":
            this.generateReadableGeneric({
              content: `failed to access file ${this.data.fileName}`,
              icon: "el-icon-user",
              type: "danger",
              link: this.accountTypeFormatted
            });
            break;
          case "DOCUMENT_VIEWED":
            this.generateReadableGeneric({
              content: "viewed a document.",
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "UPDATE_AGENCY_SUCCESS":
            this.generateReadableGeneric({
              content: "updated agency.",
              icon: "el-icon-s-home",
              link: this.accountTypeFormatted
            });
            break;
          case "UPDATE_AGENCY_FAILURE":
            this.generateReadableGeneric({
              content: "failed to update agency.",
              icon: "el-icon-s-home",
              type: "danger",
              link: this.accountTypeFormatted
            });
            break;
          case "ADD_CANCELLATION_CHANGE_REQUEST_SUCCESS":
            this.generateReadableGeneric({
              content: "created a cancellation change request.",
              icon: "el-icon-document-delete",
              link: this.accountTypeFormatted
            });
            break;
          case "UPDATE_CANCELLATION_CHANGE_REQUEST_SUCCESS":
            this.generateReadableGeneric({
              content: "updated a cancellation change request.",
              icon: "el-icon-document-delete",
              link: this.accountTypeFormatted
            });
            break;
          case "DELETE_CANCELLATION_CHANGE_REQUEST_SUCCESS":
            this.generateReadableGeneric({
              content: "deleted a cancellation change request.",
              icon: "el-icon-document-delete",
              link: this.accountTypeFormatted
            });
            break;
          case "ADD_PAY_PLAN_CHANGE_REQUEST_SUCCESS":
            this.generateReadableGeneric({
              content: "created a pay plan change request.",
              icon: "el-icon-money",
              link: this.accountTypeFormatted
            });
            break;
          case "UPDATE_PAY_PLAN_CHANGE_REQUEST_SUCCESS":
            this.generateReadableGeneric({
              content: "updated a pay plan change request.",
              icon: "el-icon-money",
              link: this.accountTypeFormatted
            });
            break;
          case "DELETE_PAY_PLAN_CHANGE_REQUEST_SUCCESS":
            this.generateReadableGeneric({
              content: "deleted a pay plan change request.",
              icon: "el-icon-money",
              link: this.accountTypeFormatted
            });
            break;
          case "ADD_POLICY_CHANGE_REQUEST_SUCCESS":
            this.generateReadableGeneric({
              content: "created a policy change request.",
              icon: "el-icon-house",
              link: this.accountTypeFormatted
            });
            break;
          case "UPDATE_POLICY_CHANGE_REQUEST_SUCCESS":
            this.generateReadableGeneric({
              content: "updated a policy change request.",
              icon: "el-icon-house",
              link: this.accountTypeFormatted
            });
            break;
          case "DELETE_POLICY_CHANGE_REQUEST_SUCCESS":
            this.generateReadableGeneric({
              content: "deleted a policy change request.",
              icon: "el-icon-house",
              link: this.accountTypeFormatted
            });
            break;
          case "ADD_CLAIMS_REQUEST_SUCCESS":
            this.generateReadableGeneric({
              content: "filed a claim.",
              icon: "el-icon-s-claim",
              link: this.accountTypeFormatted
            });
            break;
          case "UPDATE_CLAIMS_REQUEST_SUCCESS":
            this.generateReadableGeneric({
              content: "updated a claim.",
              icon: "el-icon-s-claim",
              link: this.accountTypeFormatted
            });
            break;
          case "DELETE_CLAIMS_REQUEST_SUCCESS":
            this.generateReadableGeneric({
              content: "deleted a claim.",
              icon: "el-icon-s-claim",
              link: this.accountTypeFormatted
            });
            break;
          case "GET_FISERV_CLAIMS_REQUEST_DATA_SUCCESS":
            this.generateReadableGeneric({
              content: "requested a claim from fiserv.",
              icon: "el-icon-s-claim",
              link: this.accountTypeFormatted
            });
            break;
          case "GET_FISERV_CLAIMS_REQUEST_DATA_FAILURE":
            this.generateReadableGeneric({
              content: "failed to retrieve a claim from fiserv.",
              icon: "el-icon-s-claim",
              type: "danger",
              link: this.accountTypeFormatted
            });
            break;
          case "SCREEN_VIEW":
            this.generateReadableScreenView();
            break;
          case "ADD_QUOTE_SUCCESS": {
            const links = {
              ...this.accountTypeFormatted,
              quote: {
                name: "quote",
                href: `/quotes/${this.data.quoteId}`
              }
            };
            this.generateReadableGeneric({
              content: "created a {quote}.",
              icon: "el-icon-school",
              link: links
            });
            break;
          }
          case "UPDATE_QUOTE_SUCCESS": {
            const userId = this.activity.userId;
            const fullName = this.activity.userData.fullName;
            const userUrl = `<a href="/admin/users/${userId}" style="color: #409eff">${fullName}</a>`;
            await this.generateReadableUpdateQuote(userUrl);
            break;
          }
          case "UPDATE_QUOTE_FAILURE": {
            const links = {
              ...this.accountTypeFormatted,
              quote: {
                name: "quote",
                href: `/quotes/${this.data.quoteId}`
              }
            };
            this.generateReadableGeneric({
              content: "failed to update a {quote}.",
              icon: "el-icon-school",
              type: "danger",
              link: links
            });
            break;
          }
          case "RATE_QUOTE_SUCCESS": {
            const links = {
              ...this.accountTypeFormatted,
              quote: {
                name: "quote",
                href: `/quotes/${this.data.quoteId}`
              }
            };
            this.generateReadableGeneric({
              content: "rated a {quote}",
              icon: "el-icon-school",
              link: links
            });
            break;
          }
          case "DELETE_RATINGS_SUCCESS": {
            const links = {
              ...this.accountTypeFormatted,
              quote: {
                name: "quote",
                href: `/quotes/${this.data.quoteId}`
              }
            };
            this.generateReadableGeneric({
              content: "deleted a {quote} rating",
              icon: "el-icon-school",
              link: links
            });
            break;
          }
          case "RATE_QUOTE_FAILURE": {
            const links = {
              ...this.accountTypeFormatted,
              quote: {
                name: "quote",
                href: `/quotes/${this.data.quoteId}`
              }
            };
            this.generateReadableGeneric({
              content: "failed to rate a {quote}.",
              icon: "el-icon-school",
              type: "danger",
              link: links
            });
            break;
          }
          case "DELETE_RATINGS_FAILURE": {
            const links = {
              ...this.accountTypeFormatted,
              quote: {
                name: "quote",
                href: `/quotes/${this.data.quoteId}`
              }
            };
            this.generateReadableGeneric({
              content: "failed to delete a {quote} ratings.",
              icon: "el-icon-school",
              type: "danger",
              link: links
            });
            break;
          }
          case "PRINT_QUOTE_SUCCESS":
            {
              const data = this.activity;
              this.successQuoteActivities.push(data);
            }
            break;
          case "PRINT_QUOTE_FAILURE":
            {
              const data = this.activity;
              this.failedQuoteActivities.push(data);
            }
            break;
          case "SAVE_QUOTE_SUCCESS": {
            const links = {
              ...this.accountTypeFormatted,
              quote: {
                name: "quote",
                href: `/quotes/${this.data.quoteId}`
              }
            };
            this.generateReadableGeneric({
              content: "saved a {quote}.",
              icon: "el-icon-school",
              link: links
            });
            break;
          }
          case "SAVE_QUOTE_FAILURE": {
            const links = {
              ...this.accountTypeFormatted,
              quote: {
                name: "quote",
                href: `/quotes/${this.data.quoteId}`
              }
            };
            this.generateReadableGeneric({
              content: "failed to save a {quote}.",
              icon: "el-icon-school",
              type: "danger",
              link: links
            });
            break;
          }
          case "SENDGRID_EVENT":
            this.generateReadableSendgridEvent();
            break;
          case "ADD_COMPANY_SUCCESS": {
            const newlyCreatedCompany = this.extraCreatedCompanyForActivites(
              this.data.companyId
            );
            const companyName = get(newlyCreatedCompany, "companyName", "");
            const companyNumber = get(newlyCreatedCompany, "companyNumber", "");
            this.generateReadableGeneric({
              content: `created a new company <span class='font-bold'>${companyName}</span> with company number ${companyNumber}.`,
              icon: "el-icon-school"
            });
            break;
          }
          case "ADD_COMPANY_FAILURE": {
            this.generateReadableGeneric({
              content: "failed to save a new company",
              icon: "el-icon-school",
              type: "danger"
            });
            break;
          }
          case "UPDATE_COMPANY_SUCCESS": {
            const companyId = this.activity.data.companyId;
            let htmlContent = "";
            for (const update of this.data.updates) {
              htmlContent += ` updated a company's ${update.field} to ${update.update} | <a href="/admin/companies/${companyId}" style="color: ${this.linkColor}" class="font-bold" target="_blank">View Company</a>`;
            }
            this.generateReadableGeneric({
              content: htmlContent,
              icon: "el-icon-school"
            });
            break;
          }
          case "UPDATE_COMPANY_FAILURE": {
            this.generateReadableGeneric({
              content: `failed to update this <a href="/admin/companies/${this.data.company}" style="color: ${this.linkColor}">company</a>`,
              icon: "el-icon-school",
              type: "danger"
            });
            break;
          }
        }
      },
      immediate: true
    }
  }
};
</script>

<style></style>
