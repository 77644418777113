
import Vue from "vue";
import { format } from "date-fns";
import { get } from "lodash";
import { sendGridEventTypes } from "@/helpers/activities";

export default Vue.extend({
  name: "SendGridActivity",
  props: {
    activity: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  data() {
    return {
      isOpen: false,
      eventTypes: sendGridEventTypes
    };
  },
  methods: {
    toggleAdditionalInfo() {
      this.isOpen = !this.isOpen;
    },
    formatDate(date_str: string): string {
      return format(new Date(date_str), "MMMM d, y");
    },
    getUserProfileLink(possessive = false): string {
      const accountId =
        get(this.activity, "accountId") || get(this.activity, "userData._id");
      if (!accountId || !this.userName.name || !this.userName.name) return "";
      return `<a href="/admin/users/${accountId}" class="font-medium text-atlas-blue">${
        possessive ? this.userName.self : this.userName.name
      }</a>`;
    }
  },
  computed: {
    type(): string {
      return "info";
    },
    icon(): string {
      return "check-circle";
    },
    eventTimestamp(): string {
      const timestamp = this.activity.data?.timestamp;
      if (!timestamp) return "";
      return format(new Date(timestamp * 1000), "pp");
    },
    deliveryEmail(): string {
      return this.activity.data?.email || "";
    },
    userName(): { name: string; self: string } {
      const name =
        get(this.activity, "userData.fullName", "") ||
        get(this.activity, "userData.firstName", "");
      const self = !name ? "" : name.endsWith("s") ? name + "'" : name + "'s";
      return {
        name,
        self
      };
    },
    eventType(): string {
      return get(this.activity, "data.event", "");
    },
    timelineItemType(): string {
      const type = get(this.eventTypes, this.eventType);
      return this.eventType && type ? type : "info";
    },
    messageContent(): string {
      const nameLink = this.getUserProfileLink();
      const possessiveNameLink = this.getUserProfileLink(true);
      let message = "";
      switch (this.eventType) {
        case "delivered":
          message = `Email was delivered to ${nameLink || "user"}`;
          break;
        case "processed":
          message = `Email was processed`;
          break;
        case "open":
          message = `Email was opened by ${nameLink}`;
          break;
        case "click":
          message = `Email was clicked by ${nameLink}`;
          break;
        case "bounce":
          message = `${possessiveNameLink || "User's"} email was bounced`;
          break;
        case "deferred":
          message = `${possessiveNameLink || "User's"} email was deferred`;
          break;
        case "dropped":
          message = `${possessiveNameLink || "User's"} email was dropped`;
          break;
      }

      return message;
    }
  }
});
