
import Vue from "vue";
import LoginActivity from "./LoginActivity.vue";
import LogoutActivity from "./LogoutActivity.vue";
import MessageReadActivity from "./MessageReadActivity.vue";
import DocumentReadActivity from "./DocumentReadActivity.vue";
import SendgridActivity from "./SendgridActivity.vue";

export default Vue.extend({
  components: {
    LoginActivity,
    MessageReadActivity,
    DocumentReadActivity,
    LogoutActivity,
    SendgridActivity
  },
  name: "listActivityInnerNode",
  props: {
    row: {
      type: Object
    }
  }
});
