<template>
  <div>Logged out on {{ row.createdOn | formatDateTime }}</div>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  name: "logout-activity-inner-node",
  props: {
    row: {
      type: Object
    }
  }
});
</script>
