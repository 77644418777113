<template>
  <div>
    <span>
      Recorded on
      <span class="text-gray-500"> {{ row.createdOn | formatDateTime }} </span>
    </span>
    <br />
    <span>
      Received a
      <span class="text-gray-500">{{ row.data.event }}</span> sendgrid event at
      <span class="text-gray-500">{{
        (row.data.timestamp * 1000) | formatDateTime
      }}</span>
    </span>
    <br />

    <router-link
      :to="
        `/admin/communication/${row.data.communicationId}/details/recipients`
      "
      >View Communication Recipients</router-link
    >
  </div>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  name: "sendgrid-activity-inner-node",
  props: {
    row: {
      type: Object
    }
  }
});
</script>
